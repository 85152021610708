module.exports = {
  messages: {
    // General
    Action: 'Action',
    Admin: 'Admin',
    All: 'All',
    Available: 'Available',
    avg: 'avg.',
    approve: 'Approve',
    at: 'at',
    back: 'back',
    Back: 'Back',
    Browse: 'Browse',
    backHome: 'Back home',
    goBack: 'Go Back',
    cancel: 'cancel',
    Cancel: 'Cancel',
    Copy: 'Copy',
    clear: 'Clear',
    confirm: 'confirm',
    close: 'close',
    Close: 'Close',
    Continue: 'Continue',
    Covered: 'Covered',
    created: 'created',
    Deleted: 'Deleted!',
    description: 'Description',
    done: 'done',
    duplicate: 'Duplicate',
    Edit: 'Edit',
    enterName: 'Enter Name',
    error: 'Error',
    Find: 'Find',
    unknown_error: 'An unknown error occoured',
    no: 'No',
    grade: 'grade',
    Grade: 'Grade',
    home: 'home',
    Information: 'Information',
    Invited: 'Invited',
    join: 'join',
    Label: 'Label',
    learningObject: 'Learning Object',
    Loading: 'Loading',
    location: 'location',
    Location: 'Location',
    member: 'Member',
    members: 'Members',
    mySubjects: 'My Subjects',
    Name: 'Name',
    Next: 'Next',
    next: 'next',
    note: 'Note',
    notes: 'Notes',
    text: 'Text',
    on: 'on',
    off: 'off',
    or: 'or',
    Other: 'Other',
    remove: 'Remove',
    reset: 'reset',
    results: 'results',
    Resources: 'Resources',
    role: 'role',
    save: 'save',
    Save: 'Save',
    Saved: 'Saved!',
    Saving: 'Saving…',
    Search: 'Search',
    Select: 'Select',
    send: 'send',
    sending: 'sending',
    shareToGoogleClassroom: 'Share to Google Classroom',
    shareToMicrosoftTeams: 'Share to Microsoft Teams',
    signOut: 'sign out',
    saving_Capitalized: 'Saving…',
    saved_Capitalized: 'Saved!',
    skip: 'skip',
    skip_to_main_content: 'Skip to main content',
    spin: 'SPIN!',
    start: 'start',
    Student: 'Student',
    Students: 'Students',
    subject: 'subject',
    subjects: 'subjects',
    Subject: 'Subject',
    Subjects: 'Subjects',
    allSubjects: 'All Subjects',
    Submit: 'Submit',
    Submitting: 'Submitting...',
    Teacher: 'Teacher',
    teachers: 'Teachers',
    teacherOnly: 'Teacher Only',
    tags: 'Tags',
    tryAgain: 'Try again',
    teachingNotes: 'Teaching Notes',
    Term: 'Term',
    to: 'to',
    today: 'Today',
    user: 'user',
    users: 'users',
    selected: 'selected',
    assign: 'assign',
    copy: 'copy',
    refresh: 'refresh',
    Upload: 'Upload',
    Uploading: 'Uploading...',
    yes: 'Yes',
    yesterday: 'Yesterday',
    your: 'your',
    Your: 'Your',
    assessments: 'Assessments',
    tryAgain: 'Try again!',
    resume: 'Resume',
    review: 'Review',
    // Auth screen
    Auth_invalidInfo: 'Please check your email or password',
    Auth_tooManyRequests: 'Too many login attempts. Please try again later',
    Auth_privacy_policy: 'Privacy Policy',
    Auth_terms_and_conditions: 'Terms and Conditions',
    // Breadcrumbs
    Breadcrumbs_home: 'Home',
    Breadcrumbs_searchResults: 'Search results',
    Breadcrumbs_reader: 'Reader',
    Breadcrumbs_collection: 'Collection',
    Breadcrumbs_collections: 'Collections',
    Breadcrumbs_browseCollections: 'Edwin Collections',
    Breadcrumbs_student: 'Student',
    Breadcrumbs_course: 'Course',
    Breadcrumbs_curriculum: 'Curriculum',
    Breadcrumbs_curriculumResults: 'Curriculum Results',
    // CollectionsPage
    Collection_addItems: 'Add Items',
    Collection_item: 'Item',
    Collection_items: 'Items',
    Collection_addFile: 'Add a File',
    Collection_addItemsModal1: 'Add Learning Objects to your Collection',
    Collection_addItemsModal2: 'using the',
    Collection_addItemsModal3: 'icon on the Library Results page',
    Collection_addLink: 'Add a Link',
    Collection_addItemsModal6:
      'Add anything accessible by link (url), including videos, webpages, and shared docs.',
    Collection_addItemsModal7:
      'Accepted file types are PDF, Text, EPUB, Word, PowerPoint, Excel, JPG, and PNG with a file size less than 50MB',
    Collection_addItemsModalOnline: 'You must be online',
    Collection_addTitle: 'add title',
    Collection_enterName: '| Enter you collection name',
    Collection_addDescription: 'add description',
    Collection_noSubject: 'No Subject',
    Collection_noSubjectCreate: 'Select subject(s)',
    CollectionAddLinkModal_UrlLink: 'URL Link*',
    CollectionAddLinkModal_invalidLink: 'Invalid URL',
    Collection_selectMultiple: 'Select',
    Collection_deleteSelected: 'Delete selected',
    Collection_bulkDelete_confirmMessage:
      'Are you sure you want to permanently delete ',
    Collection_EditContent: 'Edit content',
    CollectionAddLinkModal_requiredField: '* Required Field',
    CollectionAddLinkModal_uploadMsg:
      'Uploading might take up to 2 minutes depending on your connection speed…',
    CollectionsPage_title: 'Your Collections',
    CollectionsPage_titleReadOnly: 'Edwin Collections',
    CollectionCard_addAssessment: 'Add assessment',
    CollectionCard_assessment: 'Assessment',
    CollectionCard_addCurriculum: 'Add curriculum',
    CollectionCard_addDescription: 'Add description',
    CollectionCard_addDescriptionCreate:
      'Describe what this collection is about',
    CollectionCard_addSkillsCompetencies: 'Add skills/competencies',
    CollectionCard_addTeachingNotes: 'Add teaching notes',
    CollectionCard_createSelectTags: 'Create or select tags',
    CollectionCard_selectTagsCreate: 'Select or create tags',
    CollectionCard_curriculum: 'Curriculum',
    CollectionCard_lastUpdated: 'Last Updated:',
    CollectionCard_addTags: 'Add tags to improve your search',
    CollectionCard_edited: 'Edited',
    CollectionCard_forStudents: 'for Students',
    CollectionCard_skillsCompetencies: 'Skills/Competencies',
    CollectionDetail_copyName: 'new collection name',
    CollectionDetail_copyingCollection: 'Copying to Your Collections',
    CollectionDetail_cantFind: 'Sorry we can’t find a collection with this id',
    CollectionDetail_viewCollections: 'view my collections',
    CollectionDetail_lastModified: 'Last Modified:',
    CollectionDetail_removeCollection: 'remove from my collections',
    CollectionDetail_copy: 'copy to Your Collections',
    CollectionDetail_createNewCopy: 'create a new copy',
    CollectionDetail_goToCopy: 'go to existing copy',
    CollectionsFilterDropDown_allCollections: 'All collections',
    CollectionLinkItemRow_enterURL: 'Enter URL (www.nelson.com)',
    CollectionLinkItemRow_enterDescription: 'Enter a Description',
    CollectionLinkItemRow_enterTitle: 'Enter Title',
    CollectionsTagInput_selectTags: 'Select tags',
    CreateCollectionCard_header: 'New Collection',
    CreateCollectionCard_name: 'Name',
    // Dashboard
    DashboardPage_TitleDescription:
      'Self check data all in one place, updated in real-time.',
    DashboardPage_createDashboard: 'Create a new dashboard',
    DashboardPage_Dashboard: 'Dashboard',
    DashboardPage_nameInput: 'Dashboard name',
    DashboardPage_information: 'Dashboard information',
    DashboardPage_descriptionInput: 'Short description',
    DashboardPage_selectedSubject: 'Select subjects for',
    DashboardPage_subjectGuide:
      'For a more focused view, select the subjects relevant to this dashboard. This will allow you to view student progress exclusively related to these particular subjects within the dashboard.',
    DashboardPage_editLater: 'You can edit this information later.',
    DashboardPage_archiveClass: 'Archive Dashboard',
    DashboardPage_deleteClass: 'Delete Dashboard',
    DashboardPage_restoreClass: 'Restore Dashboard',
    DashboardPage_joinArchivedClass:
      'Ooops! This dashboard has been archived or does not exist. Please try again.',
    DashboardPage_joinFullClass:
      'This class you were invited to has reached its limit of 100 people.',
    DashboardPage_classOrGroupName:
      'What is the name of your dashboard or group?',
    DashboardPage_requiredFieldIsEmpty: 'A required field is empty or invalid!',
    DashboardPage_nameLengthValidation: 'Name is required (3 - 100 characters)',
    DashboardPage_descriptionLengthValidation:
      'Description should be between 3 to 255 characters!',
    DashboardPage_notesLengthValidation:
      'Notes should be between 3 to 2550 characters!',
    DashboardPage_namePlaceholder: 'Add title',
    DashboardPage_descriptionPlaceholder:
      'Add a short description of your dashboard',
    DashboardPage_notesPlaceholder:
      'Any specific notes about this dashboard that you want to share? i.e Syllabus, Expectations, Contact info',
    DashboardPage_Fetching_message:
      'We are gathering the data for you, this operation might take up to 2 minutes depending on your connection speed...',
    DashboardPage_archivedClasses: 'Archived Dashboards',
    DashboardPage_title: 'Dashboards',
    DashboardPage_joinClass: 'Please enter your class code to join:',
    DashboardPage_joinProgress: 'Joining…',
    DashboardPage_another: 'Try another code',
    DashboardPage_alreadyJoined: `You've joined this dashboard already`,
    DashboardPage_joinClassCode: 'Enter the 8-Character dashboard Code',
    DashboardPage_joinPending:
      'Request Pending. You will have access once you are approved by another teacher in the dashboard.',
    DashboardPage_joinFooter: 'Ask your teacher for an Edwin class code',
    DashboardPage_checkStudentProgress: 'View student responses',
    DashboardPage_studentProgress: 'Student Responses',
    DashboardIndividualProgress: 'Individual responses',
    DashboardMyProgress: 'My responses',
    DashboardMySelfCheckedResponses: 'My responses',
    DashboardMyDashboards: 'My dashboards',
    DashboardProgress: 'Responses',
    DashboardMembers: 'Members',
    DashboardInfo: 'Information',
    DashboardCurriculumCoverage: 'Curriculum Coverage',
    DashboardCurriculumObjective: 'Curriculum Objective',
    DashboardPlannerInstruction: `View and mark curriculum objectives for each subject in your library and review what you've already covered in this class.`,
    DashboardResetWarning:
      'This will remove all your curriculum checkmarks for',
    DashboardResetConfirm: 'Are you sure you want to proceed?',
    DashboardNoSubjects: `This dashboard doesn't have any subjects yet`,
    DashboardSelectSubjects: `If you're the dashboard creator, go to the Library and select your subjects to access the curriculum list`,
    DashboardOpenLibrary: 'Open library',
    DashboardEmptyClasses_teacherHeader: 'Create dashboards',
    DashboardEmptyClasses_teacherDesc:
      'Time to bring your students on board with Edwin! Just set up your class dashboard and share the unique class code with your students.',
    DashboardEmptyClasses_teacherSubDesc: `As they pop in this code, they'll be automatically approved and connected right to your class dashboard. Easy-peasy.`,
    DashboardEmptyClasses_teacherLearnMore: 'Learn more about dashboards',
    DashboardEmptyClasses_studentHeader: 'Follow your learning progress',
    DashboardEmptyClasses_studentDesc:
      'Joining a dashboard allows your teacher to see your progress in Edwin. Ask your teacher for a code and join a dashboard to get started',
    DashboardEmptyClasses_studentSubDesc1:
      'View your progress in Edwin by clicking on the',
    DashboardEmptyClasses_studentSubDesc2: ' My responses ',
    DashboardEmptyClasses_studentSubDesc3: 'tab icon at the top of the page',
    DashboardEmptyClasses_joinButton: 'Join a dashboard',
    DashboardEmptyClasses_createButton: 'Create your first Dashboard',
    DashboardEmptyProgress_studentDesc: `Hey there, Super Students! By answering the self-{br}check questions in your Edwin learning resources,{br}you can easily keep track of how you're doing and {br}what you're achieving. Plus, you'll see your results{br}right here - it's your personal progress meter!`,
    DashboardEmptyProgress_exploreButton: 'Explore the library',
    DashboardCreate: 'Create dashboard',
    Dashboard_ClassPage_LeftClass: `You've successfully left this dashboard`,
    Dashboard_ClassPage_ArchiveModal:
      'Are you sure you want to move this dashboard to your archived dashboards?',
    Dashboard_ClassPage_ConfirmRemove:
      'Are you sure you want to remove these users from your dashboard?',
    Dashboard_ClassPage_DeleteClass: `Are you sure you want to delete this dashboard, this action can't be reversed?`,
    Dashboard_ClassPage_EmptyInvite_Heading: 'Invite people to your dashboard',
    Dashboard_ClassPage_EmptyDescription:
      'Share a description of this dashboard here.',
    Dashboard_ClassPage_EmptyNotes: 'Share notes about this dashboard here.',
    DashboardEmptyProgress_joinYourDashboard:
      'Invite people to join your dashboard',
    Dashboard_ClassPage_EmptyInvite_Description:
      'Share the 8-character unique dashboard code with students and teachers so they can join your dashboard',
    Dashboard_ClassPage_RemoveAllTeachers_Warning:
      'Your dashboard needs at least one approved teacher.',
    Dashboard_ClassPage_Removed_Message: `You don't have access to this dashboard`,
    Dashboard_ClassPage_Removed_SubMessage:
      'Contact your administrator and try again',
    DashboardCard_avgTimeLabel: 'Avg. Time Spent',
    DashboardEmptyLO_header: 'No results found.',
    DashboardEmptyLO_subHead:
      'Please broaden your search by selecting different dates and/or subjects.',
    DashboardEmptyLO_offline1: 'You are currently offline',
    DashboardEmptyLO_offline2:
      'and have not previously retrieved the data requested. Please go online and try again.',
    DashboardStudents_avgSelfChecks: 'Avg Self-Checks',
    DashboardLO_avg: 'Avg.',
    DashboardStudents_lastViewed: 'Last Viewed',
    DashboardStudents_classDetail: 'Dashboard description',
    DashboardStudents_classDetailInfo:
      'Your teacher can share a description of this dashboard with you here.',
    DashboardStudents_classNotes: 'Notes about this dashboard',
    DashboardStudents_classNotesInfo:
      'Your teacher can share notes about this dashboard with you here.',
    DashboardStudents_leaveClass: 'Leave dashboard',
    DashboardStudents_leaveClassConfirm:
      'Are you sure you want to leave the dashboard?',
    DashboardStudents_rejoinClass:
      'You can re-join this dashboard using the unique 8-character code',
    DashboardStudents_selfChecksEntered: 'Self-Checks Entered',
    DashboardStudentsLOs_timeSpent: 'Time Spent',
    DashboardStudentsLOs_notAvailable:
      'This learning object is no longer available',
    DashboardDate_7days: 'Last 7 days',
    DashboardDate_14days: 'Last 14 days',
    DashboardDate_30days: 'Last 30 days',
    DashboardDate_60days: 'Last 60 days',
    DashboardDate_90days: 'Last 90 Days',
    DashboardDate_6months: 'Last 6 months',
    DashboardDate_9months: 'Last 9 months',
    DashboardDate_12months: 'Last 12 months',
    DashboardDate_AllTime: 'All time',
    DashboardClassCode_inviteStudents: 'Invite students',
    DashboardClassCode_infoText:
      'Share this link with students or teachers to make it easy for them to join this class dashboard.',
    DashboardClassCode_classCode: 'Class code:',
    DashboardClassCode_followLink: 'Follow this link:',
    DashboardEmptyProgress_description:
      'share this link with students or teachers{br}to make it easy for them to join this{br}class dashboard. ',
    DashboardEmptyProgress_description2:
      'This share link opens the join link and pre-fills in the class code for them.',
    DashboardEmptyProgress_description4:
      'Ask them to go to <strong>go.edwin.app/join</strong> and enter the class code to join this class dashboard.',
    DashboardEmptyProgress_learnMore: 'Learn more about Inviting people',
    DashboardClassCode_descText1: 'Go to ',
    DashboardClassCode_descText2: 'go.edwin.app/join',
    DashboardClassCode_descText3:
      ' and enter the class code above to join this class dashboard.',
    // Assessment
    Assessment: 'Assessment',
    MyAssessment: 'My assessments',
    AssessmentsMyProgress: 'My progress',
    AssessmentsEmptyStateTitle: 'There are no assessments here.',
    AssessmentsEmptyStateDescription:
      'Select a subject and see if there are any assessments for you to complete.',
    AssessmentsEmptyStateSubDescription:
      'Assessments are a great way to view your progress. They help you understand where more practice is needed and set you up for success!',
    AssessmentsEmptyStateExploreButton: 'Explore the library',
    AssessmentsLandingPageBackToBrowse: 'Back to Browse',
    AssessmentsLandingPageStartButton: 'Start!',
    AssessmentSubmissionConfirmation_Message: 'You have not answered all the questions for this assessment.',
    AssessmentSubmissionConfirmation_Question: 'Are you sure you want to submit your answers?',
    Assessment_Dashboard_Teacher_EmptyStateTitle: 'You haven’t assigned any assessments yet.',
    Assessment_Dashboard_Teacher_EmptyStateDescription: 'Assessments are a great way to view your student\'s progress. Browse by subject and find assessments for your students, once you assign them you can track their progress here.',
    // Assessments Results
    AssessmentsGoToMyDashboard: 'Go to my dashboard',
    AssessmentsHeadingRed: 'Pathway to Success!',
    AssessmentsHeadingYellow: 'Almost There!',
    AssessmentsHeadingGreen: 'Outstanding Achievement!',
    AssessmentsTextRed:
      'You are still developing your understanding. Don’t be discouraged—this is a great opportunity to revisit the material.',
    AssessmentsTextYellow:
      'You’re showing a good start in understanding some key concepts. While you did well on certain areas, there are still a few areas that need more practice. ',
    AssessmentsTextGreen:
      'Congratulations on your quiz! You’ve shown a strong understanding. Keep up the great work!',
    AssessmentsReviewYourAnswers: 'Review your answers',
    AssessmentsRetry: 'Try again',
    AssessmentsResultsYouGot: 'You got',
    AssessmentsResultsOutOf: 'out of',
    AssessmentsResultsCorrect: 'questions correct.',
    AssessmentsPractice:
      "Revisit the provided lessons to review key concepts identified in quiz results and incorporate the suggested activities into your daily math instruction to reinforce students' understanding of the curriculum expectations.",
    // DropZone
    DropZone_browseFiles: 'browse files',
    DropZone_instructions1:
      'Drag and drop your file here or <span>browse</span> your file system',
    DropZone_instructions2:
      'Accepted file types are PDF, Text, EPUB, Word, PowerPoint, Excel<br></br>JPG and PNG with a file size less than 50MB.',
    DropZone_error1:
      'The file you are trying to attach exceeds <br /> the 50Mb limit, or is the wrong file type.',
    DropZone_error2: 'Drag a new file or <span>browse</span> to attach',
    DropZone_onlineRequired:
      'You have to be online to use this feature,<br></br>please check your internet connection.',
    DropZone_uploadFailed:
      'File failed to upload, please select another file or try again later.',
    // EmptyState
    EmptyState_createCollection: 'Go to the Library',
    EmptyState_createFirstCollection: 'Create your first collection',
    EmptyStateDescription_Collections1:
      'Add Learning Objects to your Collection<br></br>using the',
    EmptyStateDescription_Collections2: 'icon on the Library Results page',
    EmptyStateDescription_LOs:
      'Start adding items to your collection.<br></br>You can add Learning Objects, files and website links!',
    EmptyStateDescription_Notes:
      'Once you have finished taking notes or highlighting important information, you can find all of your work saved automatically on this page.',
    EmptyStateTitle_Collections: 'There are no collections yet',
    EmptyStateTitle_Notes: 'You don’t have any notes yet',
    EmptyStateTitle_LOs: 'This collection is empty',
    // ErrorPages
    AppErrorPage_applicationError: 'Application Error',
    Google_info:
      'To open the "Share to Google" window press: <Enter> <Tab> <Enter>',
    PageError_somethingWrong:
      'Something went wrong. Try navigating to another page or reloading the application below.',
    LibraryError_linkError: 'Link Error',
    LibraryError_subjectMissing: 'Subject Missing',
    LibraryError_subjectMissingDesc:
      'Oops, the subject that you are looking for couldn’t be found in your settings.',
    // FormattedMainMenuButtonItems
    MenuLink_fallback: 'Main Menu Link',
    MenuButton_fallback: 'Main Menu Button',
    Notification_copied: 'Copied!',
    Notification_linkCopied: 'Link Copied!',
    // New Homepage
    HomePage_edwinHome: 'Edwin Home',
    HomePage_learningResources: '<span>Learning</span> Resources',
    HomePage_resourcesDesc:
      'Find all your learning resources in the library. Browse by subject or search for anything you would like to learn.',
    HomePage_exploreLibrary: 'Explore the Library',
    HomePage_interactiveTools: '<span>Interactive</span> Tools',
    HomePage_mathToolsDesc:
      'Bring learning to life with these interactive tools.',
    HomePage_mathToolsButton: 'All Tools',
    HomePage_yourNotes: 'Notes',
    HomePage_yourNotesDesc:
      'Review the notes and highlights you made while exploring Learning Objects.',
    HomePage_yourNotesButton: 'All Notes',
    HomePage_yourCollections: 'Collections',
    HomePage_yourCollectionsDesc:
      'Create collections to organize learning resources from the library along with your favourite website links and documents.',
    HomePage_yourCollectionsButton: 'All Collections',
    HomePage_dashboards: 'Dashboards',
    HomePage_dashboardsDesc:
      'Self-check data all in one place, updated in real-time.',
    HomePage_dashboardsButton: 'All Dashboards',
    // HomePage
    HomePage_title: 'Featured Collection',
    HomePage_needsUpdate: 'needs to be updated!',
    HomePage_versionUpdateButton: `Let's go!`,
    RecentlyViewedPanel_header: '<span>Recently</span> Viewed',
    NotificationsDrawer_Title: 'Notifications',
    NotificationsDrawer_Alerts: 'Alerts',
    NotificationsDrawer_virtualSessions: 'Virtual Sessions',
    // Learning Objects
    LearningObjectDetailModal_topic: 'Topic',
    LearningObjectDetailModal_focus: 'Focus',
    LearningObjectDetailModal_subFocus: 'SubFocus',
    LearningObjectRow_collections: 'Collections',
    LearningObjectRow_info: 'More Information',
    CollectionItem_edit: 'Edit',
    CollectionItem_delete: 'Delete From Collection',
    // LibraryHome
    LibraryHome_title1: 'Library',
    LibraryHome_title2: 'Home',
    LibraryHome_recent_subjects: 'Recently Viewed Subjects',
    LibraryHome_clear_recents: 'Clear Recently Viewed',
    LibraryHome_featured: 'Featured',
    Library_transform_description_1:
      'Choose a province and grade levels to find your most relevant subjects. You can modify these choices later by selecting the',
    Library_transform_description_2: 'icon in your list of subjects.',
    Library_select_location: 'Select your location',
    Library_save_preferences: 'Save My Preferences',
    Library_nonTransform_description:
      'license allows you to see content for your preferred',
    Library_info_link: 'Click <a>here</a> for more information.',
    Library_days_left_none:
      'The 30 day period to modify your settings has ended',
    Library_days_left_1: 'You have',
    Library_days_left_2: 'days left to modify your settings',
    Library_select_grades: 'Select your grades',
    Library_empty_subjects:
      'No available subjects. Please update your settings by clicking on the pencil icon above!',
    // LibraryBrowse
    LibraryBrowse_hideCovered: 'Hide covered',
    LibraryBrowse_mySubjects: 'My Subjects',
    LibraryBrowse_browseCurriculum: 'Browse by Curriculum',
    LibraryBrowse_browseTopics: 'Browse by Topics',
    LibraryBrowse_info2: 'Curated collections',
    LibraryBrowsePage_viewAll: 'View All',
    LibraryBrowsePage_curriculum: 'Curriculum',
    LibraryBrowsePage_noCollectionsFound:
      'No Collections found for the selected subject',
    LibraryCurriculum_description: 'Browse by curriculum',
    // LibrarySearch
    LibrarySearch_loading: 'Hold on tight, we are loading your results!',
    LibrarySearch_inputMoreChar: 'Please enter at least 2 characters',
    LibraryCollections_noResults: `Sorry, no results for `,
    'LibrarySearchPage_advanced-search': 'Advanced Search Options',
    LibrarySearchPage_popover1:
      '• Add the <strong>+</strong> key to match exact keywords. e.g. <em>earth +population</em>',
    LibrarySearchPage_popover2:
      '• Add the <strong>-</strong> key to exclude keywords. e.g. <em>earth -water</em>',
    LibrarySearchPage_popover3:
      '• Add quotes <strong>""</strong> to match exact phrases. e.g. <em>"the mother earth"</em>',
    LibrarySearchPage_searchHint: 'Search by keyword',
    LibrarySearchPage_outsideSubjects: 'Search Outside My Subjects',
    LibrarySearchPage_withinSubjects: 'Search Within My Subjects',
    LibrarySearchResults_nothingFits:
      'Sorry, but nothing fits that description.',
    LibrarySearchResults_spelledCorrectly:
      'Make sure all words are spelled correctly.',
    LibrarySearchResults_moreGeneral: 'Try more general keywords.',
    LibrarySearchResults_removeFilters:
      'Remove filters to broaden your search.',
    LibrarySearchTags_results: 'Result',
    LibrarySearchTags_searching: 'Searching...',
    // License (Admin)
    LicenseHeader_administration: 'License Administration',
    LicenseHeader_plan: 'Edwin plan: ',
    LicenseHeader_plans: 'Edwin Plans',
    LicenseHeader_termNotAvail: 'Term not available',
    LicenseTutorial_switchPlans: 'Switch between your plans',
    LicenseTutorial_inviteNew: 'Invite new users',
    LicenseTutorial_applyFilters: 'Apply filters',
    LicenseTutorial_performOptions:
      'Once you select users, perform any of these options',
    LicenseTutorial_searchUsers: 'Search users by keyword',
    LicenseTutorial_moreHelp: 'Need more help?',
    LicenseCSV_upload: 'Upload a CSV file from your computer',
    LicenseRegister_error:
      'We encountered an error inviting one or more users.',
    LicenseRegister_invalidEmail: 'This is not a valid email format.',
    LicenseRegister_invalidEmails: 'These are not valid email formats.',
    LicenseRegister_noEmails: 'Please enter at least one valid email.',
    LicenseDeactivate_error:
      'We encountered an error deactivating one or more users.',
    LicenseDeactivate_thankYou:
      'Thank you for submitting this request. We will review and follow up with you as soon as possible.',
    LicenseDeactivate_success:
      'The account(s) have been deactivated. These users will no longer be able to sign-in to Edwin.',
    LicenseDeactivate_reason: 'Please choose the reason for deactivating:',
    LicenseDeactivate_user:
      'Warning! You are about to deactivate user(s) that belong to a different school.',
    LicenseDeactivate_reasonPlaceholder:
      'Please explain the reason for this request. Once submitted, we will review the request and follow up with you.',
    LicenseDeactivate_differentSchool: 'The user is no longer at the school',
    LicenseDeactivate_mistake: 'Activated by mistake or unknown user',
    LicenseDeactivate_requested: 'Deactivate user requested',
    LicenseDeactivate_users: 'Deactivate user(s)',
    LicenseTable_none: 'None',
    LicenseTable_all_grades: 'All Grades',
    LicenseTable_all_roles: 'All Roles',
    LicenseTable_all_locations: 'All Locations',
    LicenseTable_email: 'Email',
    LicenseTable_firstName: 'First Name',
    LicenseTable_lastName: 'Last Name',
    LicenseTable_grades: 'Grade(s)',
    LicenseTable_role: 'Role',
    LicenseTable_location: 'Location',
    LicenseTable_lastSeen: 'Last Seen',
    LicenseTable_filterBy: 'Filter by',
    LicenseTable_results: 'Results for:',
    LicenseActions_export: 'Export',
    LicenseActions_deactivate: 'Deactivate',
    LicenseActions_resend: 'Resend invite',
    LicenseActions_changeRole: 'Change role',
    LicenseActions_changeLocation: 'Change Location',
    LicenseResend_alreadyRegistered:
      'The selected users are already registered.',
    LicenseResend_invitesSent: 'Invites have been sent!',
    LicenseResend_resend: 'Resend Invite',
    LicenseResend_confirmInvite:
      'Are you sure you want to resend an invitation email to these users?',
    LicenseResend_yesResend: 'Yes, resend',
    LicenseChangeRole_studentError:
      'Sorry, but you cannot change students to an admin role. If you need to do this please contact Edwin Customer Support.',
    LicenseChangeRole_changeRole: 'Change Role',
    LicenseChangeRole_changeLocation: 'Change Location',
    LicenseChangeRole_yesChangeRole: 'Yes, change role',
    LicenseChangeRole_yesChangeLocation: 'Yes, change location',
    LicenseChangeRole_confirmChange:
      'Are you sure you want to change the role of these selected users?',
    LicenseChangeLocation_confirmChange:
      'Are you sure you want to change the location of these selected users?',
    LicenseChangeRole_selectionText:
      'Change the role of the selected users to:',
    LicenseChangeLocation_selectionText:
      'Change the location of the selected users to:',
    LicenseChangeRole_success: 'The selected users role has been changed',
    LicenseChangeRole_selectRole: 'Select Role',
    LicenseChangeLocation_success:
      'The selected users location has been changed',
    LicenseChangeLocation_selectLocation: 'Select Location',
    LicenseChangeRole_error:
      'We encountered an error changing one or more user roles.',
    LicenseExport_allUsers: 'All Users',
    LicenseExport_filteredUsers: 'Filtered Users',
    LicenseExport_selectedUsers: 'Selected Users',
    LicenseExport_exportUsers: 'Export Users',
    LicenseExport_who: 'Who would you like to export?',
    LicenseKeyword_placeholder: 'Search by keyword',
    LicenseKeyword_twoChars: 'Please enter at least 2 characters',
    LicenseNoResults_title: 'No results found for ',
    LicenseNoResults_desc1: 'Make sure all words are spelled correctly.',
    LicenseNoResults_desc2: 'Try more general keywords.',
    LicenseNoResults_desc3: 'Remove filters to broaden your search.',
    License_helpCentre: 'Visit our Help Centre',
    License_fileContains: 'This file contains',
    License_fileContains2: 'new users but only',
    License_fileContains3: 'licenses are available.',
    License_fileContains4: 'Please re-upload a CSV with the correct amount.',
    License_UTF8formatted:
      'The CSV file must be UTF-8 formatted and only contain teacher email addresses.',
    License_moreInstructions:
      'For more instructions, please see this tutorial in our ',
    License_mailBoxAttached:
      'Are these email addresses with a mailbox attached? If so, would you like us to send invites with instructions to sign in?',
    License_yesSend: 'Yes, send email',
    License_noThanks: 'No, thanks',
    License_HelpCentre: 'help centre.',
    LicenseStudent_licenses: 'Student Licenses',
    LicenseTeacher_invite: 'Invite Teachers',
    LicenseTeacher_currentPlan: 'Current plan:',
    LicenseTeacher_enterEmail: 'Enter email address',
    LicenseTeacher_inviteTeachers: 'Invite teacher users',
    LicenseTeacher_Licenses: 'Teacher Licenses',
    LicenseAdmin_Licenses: 'Admin Licenses',
    LicenseTeacher_UploadCSV: 'Upload a CSV',
    LicenseAdmin_invite: 'Invite Admins',
    LicenseAdmin_inviteUsers: 'Invite admin users',
    LicenseAdmin_licensesAvail: 'admin licenses available',
    LicenseAdmin_separateEmails:
      'Separate multiple email addresses with a comma',
    LicenseAdmin_onceSubmitted1:
      'Once submitted, we will send a verification email.',
    LicenseAdmin_onceSubmitted2:
      'Admins must click the link in the email to finish registering these accounts.',
    LicenseAdmin_onceSubmitted3:
      'Please make sure the accounts listed are able to receive email.',
    LicenseAdmin_onceVerified:
      'Once verified, they will be able to sign-in to Edwin.',
    LicenseAdmin_onceVerified2:
      'In the invite email, we’ve included a link and instructions on how to verify their account. Admins should check their inboxes and perhaps their spam folder to find this email.',
    LicenseAdmin_onceVerified3: 'Thanks for growing the Edwin family!',
    LicenseTeacher_invitationsSent: 'Invites have been sent!',
    LicenseTeacher_accountsActive: 'These Teacher accounts are now active.',
    LicenseTeacher_accountsActive2:
      'These teacher accounts are now active. They can sign in to Edwin right away by using the link and instructions in the invite email. Teachers should check their inboxes, or perhaps their spam folder now.',
    LicenseTeacher_accountsActive3: 'Thanks for growing the Edwin family!',
    LicenseTeacher_accountsActiveLarge:
      'Your invites are being processed! It may take a few minutes for all teacher accounts to become active.',
    LicenseTeacher_teacherAccountsActive: 'Teacher accounts active',
    LicenseTeacher_letThemKnow:
      'Please let them know they can begin using Edwin.',
    LicenseTeacher_gettingWordOut: 'Need help getting the word out?',
    LicenseTeacher_downloadDetailsFlyer:
      'Download a flyer with all the details',
    LicenseTeacher_separateEmails:
      'Separate multiple email addresses with a comma',
    LicenseTeacher_invitationsSpreadsheet:
      ' Want to invite a lot of Teachers from a spreadsheet?',
    // Misc
    Authenticated_authorizing: 'Authorizing...',
    Authenticated_loggedIn:
      'You must be logged into the Edwin app to view this page.',
    Authenticated_unsavedWork:
      'If you have unsaved work please keep this page open and login to edwin in another window',
    LoadingStatusBar_offlineMsg: `You're currently offline. Data may be stale.`,
    ConfirmModal_confirmDelete:
      'Are you sure you want to permanently delete this collection?',
    ConfirmModal_confirmDeleteItem:
      'Are you sure you want to remove this item from your copy of this collection?',
    ConfirmModal_confirmDeleteScreenshot:
      'Are you sure you want to permanently delete this item?',
    ConfirmModal_teacherOnly:
      'This is a Teacher Only item<br></br>Are you sure you want to proceed?',
    // MathTools
    FractionStrips_title: 'Fraction Strips',
    FractionStrips_descTop: 'Drag and drop fraction strips here!',
    FractionStrips_descBottom:
      'Connect fractions together to see how they measure up.',
    FractionStrips_infoDragRows: 'Drag the four dots to move an entire row.',
    FractionStrips_infoDragStrips:
      'Drag the strips from the drawer to arrange them in the workspace. You may need to press and hold for a second before dragging.',
    FractionStrips_click:
      'Click on the strips to randomly arrange them in the workspace.',
    FractionStrips_infoTrash: 'Drag individual strips here to delete.',
    InteractiveTools_whiteboard: 'Whiteboard',
    MathTools_Instruction_Draw: 'Draw and erase on the workspace.',
    MathTools_screenshot_desc: 'Save a screenshot of the workspace.',
    MathTools_Instruction_Resize: '<span>Resize the</span><span>window.</span>',
    MathTools_Instruction_Reset: 'Reset the workspace.',
    MathTools_Instruction_ResetSpinner: 'Reset the counter.',
    MathTools_Instruction_Delete_Block:
      '<span>Drag blocks here</span><span>to delete.</span>',
    MathTools_Instruction_Delete_Tiles:
      '<span>Drag tiles here</span><span>to delete.</span>',
    MathTools_Instruction_Delete_Chips:
      '<span>Drag chips here</span><span>to delete.</span>',
    MathTools_Instruction_Delete_Items:
      '<span>Drag items here</span><span>to delete.</span>',
    MathTools_fractionStrips: 'Fraction Strips',
    MathTools_patternBlocks: 'Pattern Blocks',
    MathTools_patternBlocks_Empty_Title: 'Drag and drop pattern blocks here!',
    MathTools_patternBlocks_Empty_Description:
      'Use blocks to explore fractions, geometry, and patterning.',
    MathTools_patternBlocks_Instruction_Rotate:
      '<span>Tap on a block</span><span>to rotate.</span>',
    MathTools_patternBlocks_Instruction_Box:
      'Drag blocks from the drawer to arrange them in the workspace.',
    MathTools_patternBlocks_Instruction_Click:
      'Click on the blocks to randomly arrange them in the workspace.',
    MathTools_patternBlocks_Instruction_Background: 'Show/hide the background.',
    MathTools_algebraTiles: 'Algebra Tiles',
    MathTools_algebraTiles_Empty_Title: 'Drag and drop algebra tiles here!',
    MathTools_algebraTiles_Empty_Description:
      'Use tiles to represent variables and constants.',
    MathTools_algebraTiles_Instruction_Rotate:
      '<span>Tap on a tile</span><span>to rotate.</span>',
    MathTools_algebraTiles_Instruction_Box:
      'Drag tiles from the drawer to arrange them in the workspace. You can also drag an equal sign.',
    MathTools_algebraTiles_Instruction_Background: 'Show/hide the frame.',
    MathTools_algebraTiles_Instruction_Click:
      'Click on the tiles to randomly arrange them in the workspace.',
    MathTools_integerChips: 'Integer Chips',
    MathTools_integerChips_Instruction_Box:
      'Drag individual integer chips or zero pairs from the drawer to arrange them in the workspace. You can also drag operations signs here.',
    MathTools_integerChips_Rotate: 'Tap on a zero pair to rotate.',
    MathTools_integerChips_Instruction_Click:
      'Click on the chips to randomly arrange them in the workspace.',
    MathTools_baseTenBlocks: 'Base Ten Blocks',
    MathTools_baseTenBlocks_Instruction_Box:
      'Drag blocks from the drawer to arrange them in the workspace. You can also drag operations signs here.',
    MathTools_barModel: 'Bar Model',
    MathTools_barModel_addLabel: 'Add a label to your bars.',
    MathTools_barModel_click:
      'Click on the bars, brackets, and lines to randomly arrange them in the workspace.',
    MathTools_barModel_resize: 'Drag to resize.',
    Mathtool_screenshot_button: 'Screenshot',
    Mathtool_close: 'Close',
    Mathtool_instructions: 'Instructions',
    Mathtool_grid: 'Grid',
    Mathtool_frame: 'Frame',
    'mathtools-drawing-menu-draw': 'Draw',
    MathTool_resize: 'Resize',
    'mathtools-drawing-menu-erase': 'Erase',
    Mathtool_clear: 'Reset',
    Mathtool_resetConfirmation:
      'Hey there! This will wipe the canvas clean, like a fresh sheet of paper. Ready to start over?',
    Mathtool_trash: 'Delete',
    MathToolError: 'Saving error, please try again',
    MathTools_barModel_description:
      'Drag bars from the drawer to arrange and re-size them in the workspace. You can also drag brackets and lines here. ' +
      'You can place labels on top of your bars or beside them in the workspace.',
    MathTools_Instruction_Scroll:
      '<span>Scroll down for</span><span>additional tiles.</span>',
    MathTools_Instruction_Drag:
      'Draw a box then click on the trash icon to delete multiple objects.',
    MathTools_geometryTools: 'Geometry Tools',
    MathTools_graphingCalculator: 'Graphing Calculator',
    MathTools_scientificCalculator: 'Scientific Calculator',
    MathTools_spinner_PossibleOutcomes: 'Possible Outcomes',
    MathTools_spinner: 'Spinner Wheel',
    MathTools_spinner_NumberSpins: 'Number of spins',
    MathTools_spinner_ResetCounter: 'Reset counter',
    MathTools_spinner_Instruction_Spin: 'Press to spin the spinner.',
    MathTools_spinner_Instruction_PossibleOutcomes:
      'Select the number of possible outcomes between 2-10.',
    MathTools_spinner_Instruction_EnterLabel:
      'Enter your label and choose the colour.',
    MathTools_spinner_Instruction_TrackSpins:
      'Keep track of the number of spins.',
    MathTools_spinner_Instruction_ResetNumber:
      '(The number will reset everytime you change the number of possible outcomes).',
    // NavMenu
    NavMenu_home: 'Home',
    NavMenu_library: 'Library',
    NavMenu_collections: 'Your Collections',
    NavMenu_dashboard: 'Your Dashboards',
    NavMenu_help: 'Help',
    NavMenu_admin: 'License Administration',
    NavMenu_notifications: 'Notifications',
    NavMenu_dashboardToast: 'Create a Class Dashboard and Invite Students',
    NavMenu_notificationToast: 'You have a new notification',
    NavMenu_settings: 'Settings',
    NavMenuTools_SaveToGoogleDrive: 'Save to Google Drive',
    NavMenuTools_SaveToYourNotes: 'Save to Your Notes',
    // Reader
    ReaderLoading_offline:
      'It looks like you are offline, please check your connection',
    ReaderLoading_longLoad: 'Loading is taking longer than usual',
    ReaderLoading_tryAgain: 'Try Again',
    ReaderLoading_reload: 'Reload',
    ReaderError_notFound: 'Oops! This item couldn’t be found anywhere',
    ReaderError_notFoundLibrary:
      'Oops! This item couldn’t be found in your Library',
    ReaderError_restricted: 'Restricted Content',
    ReaderError_teacherOnlyInfo: `You don't have access to this content,<br></br>please contact your teacher to get access.`,
    ReaderError_desc1:
      'In order to access this item, ask your teacher what grade and subject it belongs to and add it to your library through the',
    ReaderError_desc2: 'Library',
    ReaderError_desc3: 'page',
    ReaderControls_navCollection: 'Collection:',
    ReaderControls_print: 'Print',
    ReaderControls_whiteboard: 'Whiteboard',
    ReaderControls_collections: 'Collections',
    ReaderControls_sourceInfo: 'Source Info',
    ReaderControls_markAsCovered: 'Mark as covered',
    ReaderControls_noDashboards: `You haven't created any class Dashboards`,
    ReaderControls_markAsCoveredDesc:
      'Mark this resource as covered for each class and keep track of your curriculum coverage in the ',
    ReaderControls_markAsCoveredDescLink: 'Dashboard',
    ReaderControls_noCurriculum:
      'This resource does not have curriculum associated with it.',
    ReaderControls_educatorTools: 'Educator Tools',
    ReaderControls_selfCheck: 'Self Check',
    ReaderControls_related: 'Related Resources',
    ReaderControls_notes: 'Notes',
    ReaderControls_search: 'Search',
    ReaderControls_sharableLink: 'Get Sharable Link',
    ReaderControls_duplicateCollection: 'Duplicate Collection',
    ReaderControls_textSize: 'Change Text Size',
    ReaderControls_mathTools: 'Interactive Tools',
    ReaderControls_TTS_play: 'Play',
    ReaderControls_TTS_restart: 'Start Over',
    ReaderControls_TTS_rewind: 'Rewind',
    ReaderControls_TTS_forward: 'Forward',
    ReaderControls_TTS_pause: 'Pause',
    ReaderControls_TTS_resume: 'Resume',
    ReaderControls_TTS_voiceSettings: 'Voice Settings',
    ReaderControls_TTS_speed: 'Speed',
    ReaderPage_maximumImages:
      'The maximum amount of screenshot images are 25, please delete one from your notes to save another.',
    ReaderPage_saveToNotes: 'Where do you want to save your screenshot?',
    ReaderPage_download: 'Download',
    ReaderPage_downloading: 'Downloading', // note this is displayed dynamically in ScreenshotOptions.tsx
    AssignModal_title: 'Title*',
    AssignModal_desc: 'Description',
    Citation_sourceInfo: 'Source Info',
    Citation_accessInfo:
      '<b>Access Information:</b> Retrieved from <i>Edwin</i> on',
    Citation_authors: '<b>Author(s): </b>',
    Citation_title: '<b>Title: </b>',
    Citation_publisher: '<b>Publisher: </b>',
    Citation_date: '<b>Date: </b>',
    Citation_website: '<b>Website Name: </b> Edwin',
    CollectionsDropDown_add: 'Add to Collection',
    CollectionsDropDown_added: 'Added!',
    CollectionsDropDown_new: 'New Collection Title (3 - 100 characters)',
    CollectionsDropDown_create: 'Create collection',
    CrossLinkedBackButton_return: 'Return to:',
    EducatorTools_educatorTools: 'Educator Tools',
    EducatorTools_offline1: 'Educator Tools are unavailable',
    EducatorTools_offline2:
      'You have to be online to use this feature, please check your connection.',
    EducatorTools_teacherSupport: 'Teacher Support',
    EducatorTools_activities: 'Activities and Tools',
    EducatorTools_development: 'Professional Development',
    EducatorTools_teachingNotesToggleOn: 'On',
    EducatorTools_teachingNotesToggleOff: 'Off',
    EducatorTools_teachingNotesToggle: 'Teacher Notes',
    EducatorTools_presentationMode: 'Presentation Mode',
    WhiteboardPanel_title: 'Whiteboard',
    NotesPanel_notes: 'Notes',
    NotesPanel_takeNotes:
      'Take notes here. Your notes and highlights will be automatically saved.',
    NotesPanel_lastEdit: 'Last edit',
    NotesPanel_deleteImg: 'Delete Image',
    NotesPanel_saveToDocs: 'Save to Google Drive',
    NotesPanel_sortByColor: 'Sort by color',
    NotesPanel_highlights: 'Highlights',
    NotesPanel_download: 'Download notes and highlights',
    NotesPanel_emptyNote: 'Cannot save empty note.',
    NotesPanel_tryAgain: 'Please try again.',
    NotesPanel_infoDropdown1: 'Looks like you already have a doc named',
    NotesPanel_infoDropdown2: 'stored in your Google Drive.',
    NotesPanel_infoDropdown3:
      'Do you want to overwrite it with this new version?',
    NotesPanel_cookies_disabled:
      'Please enable cookies and refresh for Edwin to export a note',
    NotesPage_sortByDate: 'Sort by date',
    NotesPage_sortByTitle: 'Sort by title',
    PrintInfo_info:
      'Printing or modification of Edwin materials is permitted only as needed to accommodate the needs of users with disabilities or impairments.',
    PrintInfo_msg:
      'I am printing this material to comply with an accessibility policy.',
    PrintInfo_agree: 'I agree',
    RelatedLearningObjects_related: 'Related Resources',
    RelatedLearningObjects_moreHelp: 'More Help',
    RelatedLearningObjects_moreOnTopic: 'More on This Topic',
    SearchPanel_search: 'Search',
    SearchPanel_searchLower: 'search',
    SearchPanel_searchInput: 'search this page',
    SearchPanel_toc: 'Table of Contents',
    SearchPanel_infoButton: 'More Information',
    SearchPanel_noResults: 'No results found',
    SearchPanel_result1: 'result',
    SearchPanel_result2: 'found',
    SelfAssessment_selfCheck: 'Self Check',
    SelfAssessment_empty: 'No self-check questions for this learning object',
    SelfAssessment_choiceHigh: 'Got it!',
    SelfAssessment_choiceMed: 'Almost there',
    SelfAssessment_choiceLow: 'Still working on it',
    SelfAssessment_seeResponses: 'See Responses',
    // Settings
    SettingsResources_proceed: 'proceed',
    SettingsChangeInfo_header: 'Edit your information',
    SettingsChangeInfo_name: 'Name',
    SettingsChangeInfo_givenName: 'First Name',
    SettingsChangeInfo_familyName: 'Last Name',
    SettingsChangeInfo_teacherEmail: 'Alternative email',
    SettingsChangeInfo_school: 'School',
    SettingsChangeInfo_teacherType: 'How will you be using Edwin?',
    SettingsChangeInfo_requiredError: 'Please enter your full name.  ',
    SettingsChangeInfo_validationError: `I'm sorry, our system can't accept that name`,
    SettingsChangeInfo_emailRegexError: 'Please enter a valid email address',
    // SettingsPage
    SettingsPage_title: 'Settings',
    SettingsPage_language: 'Language',
    SettingsPage_NewTeacherModal_Enable: 'Show tutorial on home page',
    SettingsPage_NewTeacherModal_Disable: 'Hide tutorial on home page',
    UserProfile_email: 'Email:',
    UserProfile_teacherEmail: 'Alternative email:',
    UserProfile_account: 'Account:',
    UserProfile_school: 'School:',
    UserProfile_type: 'How will you be using Edwin:',
    UserProfile_signOut: 'Sign out',
    UserProfile_edit: 'Edit your information',
    UserProfile_signOutConfirm:
      'Are you sure you want to<br></br> sign out of Edwin?',
    UserProfile_footnote: '*Your login email cannot be changed',
    // Home New Teacher Modal
    HomeNewTeacherModal_Heading: 'Welcome!',
    HomeNewTeacherModal_Desc:
      'Follow these steps to get yourself and your class up and running with Edwin this year.',
    HomeNewTeacherModal_Quickstart: 'Quick Start',
    HomeNewTeacherModal_Quickstart_Teachers: 'for Teachers',
    HomeNewTeacherModal_Quickstart_Students: 'for Students',
    HomeNewTeacherModal_Invite1: 'Invite Your',
    HomeNewTeacherModal_Invite2: 'Students',
    HomeNewTeacherModal_Replay: 'Replay',
    HomeNewTeacherModal_Dismiss: 'Remind me later',
    HomeNewTeacherModal_DontShowAgain: `Don't show this again`,
  },
}
